
.section {
  text-align: center;
  color: #fff;
  .fp-next {
    right: 45px!important;
  }

  .fp-controlArrow {
    z-index: 999999 !important;
    @include breakpoint(mobileonly){
      display:none;
    }
  }
  .sf {
    position: absolute;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    display: flex;
    display: -webkit-flex;
    flex-flow: column;
    -webkit-flex-flow: column;
    align-items: center;
    text-shadow: 2px 2px #000;
    .h1 {margin: auto;}
  }

  .wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    background: rgba(0,0,0, 0.4);
    flex-flow: column;
  }


  a {
    font-size: 25px;
    text-underline: none;
  }
  .slide1 {
    background-image: url("/img/slide/slide1.jpg");
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: #666;

  }
  .slide2 {
    background-image: url("/img/slide/slide2.jpg");
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: #666;

  }
  .slide3 {
    background-image: url("/img/slide/slide3.jpg");
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: #666;

  }

}



