body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  h1,h2,h3,h4,h5,h6 {font-family: 'Raleway', sans-serif;}

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }

  .footer{
    max-width: 80%;
    margin:auto;
  }
  .MuiFormControl-fullWidth-33, .jss33 {
    max-width: 50%!important;
    margin: 1px!important;
  }

  button {
    margin: 5px!important;
  }

  .fas {font-size: 30px;}

  address {text-align: left;}
  address a {color:white;}

  .fb_dialog {
    bottom: 30pt!important;
  }

  .btn {
    color:#fff;
    a {
      color: white;
      max-width: 18px;
    }
  }

  .hide-on-mobile {
    @include breakpoint(mobileonly) {
      display: none;
    }
  }
  .swipe {
    @include breakpoint(mobileonly) {
      display: inline-block;
    }
  }
}

