/* Mixins */
/* Basic */
body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body h1, body h2, body h3, body h4, body h5, body h6 {
    font-family: 'Raleway', sans-serif; }
  body code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }
  body .footer {
    max-width: 80%;
    margin: auto; }
  body .MuiFormControl-fullWidth-33, body .jss33 {
    max-width: 50% !important;
    margin: 1px !important; }
  body button {
    margin: 5px !important; }
  body .fas {
    font-size: 30px; }
  body address {
    text-align: left; }
  body address a {
    color: white; }
  body .fb_dialog {
    bottom: 30pt !important; }
  body .btn {
    color: #fff; }
    body .btn a {
      color: white;
      max-width: 18px; }
  @media (max-width: 37.5em) {
    body .hide-on-mobile {
      display: none; } }
  @media (max-width: 37.5em) {
    body .swipe {
      display: inline-block; } }

/* Components */
.section {
  text-align: center;
  color: #fff; }
  .section .fp-next {
    right: 45px !important; }
  .section .fp-controlArrow {
    z-index: 999999 !important; }
    @media (max-width: 37.5em) {
      .section .fp-controlArrow {
        display: none; } }
  .section .sf {
    position: absolute;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    display: flex;
    display: -webkit-flex;
    flex-flow: column;
    align-items: center;
    text-shadow: 2px 2px #000; }
    .section .sf .h1 {
      margin: auto; }
  .section .wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    background: rgba(0, 0, 0, 0.4);
    flex-flow: column; }
  .section a {
    font-size: 25px;
    text-underline: none; }
  .section .slide1 {
    background-image: url("/img/slide/slide1.jpg");
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: #666; }
  .section .slide2 {
    background-image: url("/img/slide/slide2.jpg");
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: #666; }
  .section .slide3 {
    background-image: url("/img/slide/slide3.jpg");
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    background-color: #666; }

.logo {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 100px;
  z-index: 99;
  text-shadow: 2px 2px #000; }

.instagram {
  background-image: url("/img/slide/instagram.jpg");
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #666; }
  .instagram img {
    transition: all 0.2s; }
    .instagram img:hover {
      -webkit-transform: rotate(7deg);
              transform: rotate(7deg); }
  .instagram #card {
    margin: auto;
    max-height: 348px;
    overflow: hidden; }
  .instagram .btn {
    display: block;
    max-width: 220px;
    margin: 0 auto !important; }
  .instagram .instagram-item {
    height: 200px;
    width: 200px;
    background-size: cover;
    background-position: center;
    display: inline-block;
    margin: 0.5rem 0.5rem 5rem;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.61);
    transition: all 0.2s; }
    .instagram .instagram-item:hover {
      -webkit-transform: rotate(5deg);
              transform: rotate(5deg);
      box-shadow: 5px 5px 5px 0px rgba(255, 255, 255, 0.92); }

.menu {
  color: #fff;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
  text-shadow: 2px 2px #000; }
  .menu a {
    font-size: 18px;
    color: white;
    max-width: 18px; }

.footer {
  color: #fff;
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 100; }
  .footer address {
    text-shadow: 2px 2px #000; }

.branding {
  color: #fff;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  text-shadow: 2px 2px #000;
  font-size: 12px;
  text-align: right; }
  .branding a {
    color: #fff; }

