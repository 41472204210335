.footer{
  color:#fff;
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  address{
    text-shadow: 2px 2px #000;
  }
}

.branding{
  color:#fff;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  text-shadow: 2px 2px #000;
  font-size: 12px;
  text-align: right;
  a{
    color:#fff;
  }
}