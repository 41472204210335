.menu {
  color:#fff;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
  text-shadow: 2px 2px #000;
  a {
    font-size: 18px;
    color: white;
    max-width: 18px;
  }
}