.instagram {
  background-image: url("/img/slide/instagram.jpg");
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #666;
  img {
    transition: all 0.2s;
    &:hover {
      transform: rotate(7deg);
    }
  }
  #card {
    margin: auto;
    max-height: 348px;
    overflow: hidden;
  }
  .btn {
    display: block;
    max-width: 220px;
    margin: 0 auto!important;
  } 
  .instagram-item {
    height: 200px;
    width: 200px;
    background-size: cover;
    background-position: center;
    display: inline-block;
    margin: 0.5rem 0.5rem 5rem;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.61);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.61);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.61);
    transition: all 0.2s;
    &:hover {
      transform: rotate(5deg);
      box-shadow: 5px 5px 5px 0px rgba(255, 255, 255, 0.92);
    }
  }
}